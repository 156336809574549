import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 22,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                "I absolutely loved this place. We attended a Mother-Son dance for Valentine's and the owners and staff went over the top to make sure we had a wonderful time. I found the facility to be bright and clean. Will definitely be back - THANK YOU SO MUCH!"
                            </p>
                            <p className="overview">-- SK Walmsley on Google</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "We took our 2 granddaughters there today and they loved it!! It was so much fun. The zip line and riding the blue car was a big hit. We will definitely be back."
                            </p>
                            <p className="overview">-- Deborah Wellar on Google</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "Amazing place for birthday parties and special events like Boo Bash, etc, are super fun!"
                            </p>
                            <p className="overview">
                                -- Mar S on Google
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                "Very inclusive indoor play area. The kids have fun. Now I feel the swings can be place in the back instead to prevent kids for being knock over when is open play hour."
                            </p>
                            <p className="overview">
                                -- Idhalys Morales (Ms Morales) on Google
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                "We Rock The Spectrum is a fantastic children’s gym! Starting with the facilities, which are very clean to the management and staff who are engaging, friendly, helpful, and responsive to any requests/concerns the parents may have. The pricing is fair and there is a lot for the children to do including a trampoline, rock wall, zip line, and swings among other things. I highly recommend bringing your children here for a play day."

                            </p>
                            <p className="overview">
                                -- Josiah David on Google
                            </p>
                        </div>
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                "My son loves we rock the spectrum !It's always a non judgemental zone with the sweetest staff!"

                            </p>
                            <p className="overview">-- kahnila mcleod on Google</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                We loved this place! My kids are 5, 3, and 18 months. There is something to do for each and every one of them without it ever feeling overwhelming or crowded. The kids have made friends every time and they absolutely love going! In fact, they never want to leave and the "best part" of their day is always Kid's Rock the Spectrum Kid's Gym!"

                            </p>
                            <p className="overview">-- Christina McKean on Google</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "This facility was just simply amazing. The staff was very kind and professional. They made our daughter’s 4th Birthday Party a success. A great facility for children on the spectrum."

                            </p>
                            <p className="overview">
                                -- Lydell UnbreakableEight Anderson on Facebook
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                "This place is amazing!! My kids were so upset when it was time to leave. Everybody was so friendly and there were so many different activities for kids. We definitely will be coming back a lot!! 10/10 recommend. I can’t wait to try a parents night out!!"

                            </p>
                            <p className="overview">
                                -- Monicka Hernandez on Google
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                "Very clean, friendly staff, very good experience"

                            </p>
                            <p className="overview">
                                -- Rogério Amoreli on Google
                            </p>
                        </div>
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                "Absolutely loved our day at we rock the spectrum. The staff was super sweet. The gym was clean and my spectrum kid loved it ?? we will be back"

                            </p>
                            <p className="overview">-- Kristen Kwait Mitrovich on Facebook</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "We went for the first time today. I was so excited to see him enjoying this incredible place. There are so few places he can enjoy like a typical kid. The place was clean and the staff was so kind."

                            </p>
                            <p className="overview">-- James Mitrovich on Google</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "Great indoor playground."

                            </p>
                            <p className="overview">
                                -- Dean S on Google
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                "We had our sons 3rd birthday here. He’s non verbal and on the spectrum. He and his guests all had a blast. Highly recommended. There was something for everyone and the kids ranged from 2-12.

                            </p>
                            <p className="overview">
                                -- Georgia NFA on Google 
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                "I’ve been twice with my daughter and I’ve never felt more comfortable and enjoyed every minute."

                            </p>
                            <p className="overview">
                                -- Celestial Flower on Facebook
                            </p>
                        </div>
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                "We had so much fun playing! It’s a safe environment for kids of all ages. My 19 month old absolutely loved it!"

                            </p>
                            <p className="overview">-- Sarah Ann Morrison on Facebook</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "My son had a great time playing at We Rock The Spectrum. He loved the big car with the ramp and the train set best. We're definitely going back."

                            </p>
                            <p className="overview">-- Blake Fertig on Facebook</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                "This place is great. So many things for sensory , learning, and creativity . A special safe place and the owners keep it very clean and limit the number of children . You can even rent the whole space out !"

                            </p>
                            <p className="overview">
                                -- Meredith Angevine on Facebook
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                This playground is a little smaller than other indoor playgrounds and it's more expensive but we will be back here many times. They make a fantastic use of the space. There's so much to play with in this small area. They have really maximized the space…

                            </p>
                            <p className="overview">
                                -- Austin H. on Yelp
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                I wasn't sure my 2 year old liked playing in their 2 hour open play session until we went to leave and he started crying. They have space for birthdays and you can do private play. We have only done the open play sessions but hope to do the parent drop off, parents night out and social group soon!

                            </p>
                            <p className="overview">
                                -- Chloe S. on Yelp
                            </p>
                        </div>
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                This place brought so much joy to the whole family. The pictures don't really do this place justice of how many different things there are to do here. They have a zip line for the kids. Monkey bars, a few different climbing activities including the rock wall. Swings for the babies and they even have a peaceful room. My kids spent most of the time with all the toys…

                            </p>
                            <p className="overview">-- Briana E. on Yelp</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                Brought my grandkids there a couple weeks ago. They are two, and three years old and had an absolute blast!! We'd gone to Chuck E. Cheese a month before, and way too much money and the kids had a much better time here. As the name implies, it's a great place for kids on the spectrum, several who were there with their coaches. My grands are not on the spectrum, but it's clearly designed for children of all abilities. We will definitely be back many times.!

                            </p>
                            <p className="overview">-- Betsy J. on Yelp</p>
                        </div>
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
